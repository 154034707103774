import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';
import ContentInput from "../Model/ContentInput";

import MockUpImage from '../../../image/MockUp.png';
import FetchModule from "../../../Model/Share/Network/FetchModule";

import NotRegistrationCardModal from '../../../Model/Share/Normal/NotRegistrationCardModal';
import SendCompleteModal from "../Model/SendCompleteModal";
import LoadingAnimation from '../../../Animation/LoadingAnimation.json';


export default function MessageSendPage() {
  const history = useHistory();

  const [isSending, setIsSending] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [content, setContent] = useState('');

  const [isClickedValid, setIsClickedValid] = useState(false);
  const [isClickedInvalid, setIsClickedInvalid] = useState(false);
  const [isRegistrationCard, setIsRegistrationCard] = useState(false);

  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);

  const [isViewRegistrationCard, setIsViewRegistrationCard] = useState(false);
  const [isViewCompleteSend, setIsViewCompleteSend] = useState(false);

  // API 호출 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('message/status', 'GET');

        if (responseData.status === 200) {
          setStoreName(responseData.data.storeName);
          setValidCount(responseData.data.validCount);
          setInvalidCount(responseData.data.invalidCount);
          setIsRegistrationCard(responseData.data.isRegistrationCard);
        }
      }
    )();
  }, [])

  // 회원수 계산 //
  function decideMemberCount() {
    let count = 0;

    if (isClickedValid) count += validCount;
    if (isClickedInvalid) count += invalidCount;

    return count.toLocaleString('ko-KR');
  }

  // 결제 금액 //
  function decideCalcPrice() {
    let count = 0;

    if (isClickedValid) count += validCount;
    if (isClickedInvalid) count += invalidCount;

    return (60 * count).toLocaleString('ko-KR');
  }

  // 완료모달 텍스트 결정 //
  function decideCompleteMemberCount() {
    if (isClickedValid && isClickedInvalid) return '유효 회원, 만료 회원 ' + (validCount + invalidCount) + '명 에게';
    else if (isClickedValid) return '유효 회원 ' + (validCount) + '명 에게';
    else if (isClickedInvalid) return '만료 회원 ' + (invalidCount) + '명 에게';
    else return '회원 0명 에게';
  }

  // 입력하기 버튼 클릭시 //
  function onClickRegistrationCard() {
    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'AUTH'
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_CARD_VER = '01';
    obj.PCD_PAYER_AUTHTYPE = 'sms';
    // obj.PCD_RST_URL = process.env.REACT_APP_TEST_HOSTNAME + '/subscribe/success';
    // obj.PCD_RST_URL = 'https://link-soft.io/';

    // obj.PCD_PAY_GOODS = 'Link-Zone 정기 구독권 (1개월)';
    // obj.PCD_PAY_TOTAL = 100;
    // obj.PCD_PAY_OID = responseData.data[0].nextMerchantUid;


    obj.callbackFunction = getResult;
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    
    window.PaypleCpayAuthCheck(obj);
  }
  
  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
      let requestData = {
        billingKey: res.PCD_PAYER_ID,
        cardName: res.PCD_PAY_CARDNAME,
        cardNum: res.PCD_PAY_CARDNUM
      }
      // console.log(requestData);
      // console.log(res);
      // console.log(res.PCD_PAYER_ID);
      // console.log(res.PCD_PAYER_NO);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('billing/registry', 'POST', requestData);

          if (responseData.status === 200) {
            alert('카드번호 등록이 완료되었습니다!');
            setIsRegistrationCard(true);
            setIsViewRegistrationCard(false);
          }
          else if (responseData.status === 201) {
            alert('이미 등록된 정보입니다\n새로고침 후 다시시도해 주세요');
            setIsViewRegistrationCard(false);
          }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }

  // 메시지 보내기 버튼 클릭시 //
  function onClickSendMessage() {
    let count = 0;

    if (isClickedValid) count += validCount;
    if (isClickedInvalid) count += invalidCount;

    if (!isClickedValid && !isClickedInvalid) {
      alert('메시지를 전송할 회원 유형을 선택해주세요');
      return;
    }
    else if (count === 0) {
      alert('전송가능한 회원이 없습니다');
      return;
    }

    if (isRegistrationCard) {
      if (isSending) {
        alert('메세지를 전송중입니다');
        return;
      }

      setIsSending(true);

      let requestData = {
        title: storeName,
        content: content,
        isSendValid: isClickedValid,
        isSendInvalid: isClickedInvalid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('message/send', 'POST', requestData);

          if (responseData.status === 200) {
            // alert('메시지 전송이 완료되었습니다.');

            setTimeout(() => {
              setIsViewCompleteSend(true);
              setIsSending(false);
              setContent('');
              setIsClickedValid(false);
              setIsClickedInvalid(false);
            }, 600)
          }
          else {
            alert('메시지 전송에 실패하였습니다\n잠시 후 다시시도해주세요');
            setIsSending(false);
          }
        }
      )();

      // setIsViewCompleteSend(true);
      // setIsSending(false);
    }
    else setIsViewRegistrationCard(true);
  }

  return (
    <TopLevelWrapper>
      { isViewCompleteSend && <SendCompleteModal text={decideCompleteMemberCount()} onClickExit={() => setIsViewCompleteSend(false)} /> }
      { isViewRegistrationCard && <NotRegistrationCardModal division='Notice' setIsViewModal={setIsViewRegistrationCard} onClickRegistCard={onClickRegistrationCard}/> }
      <TitleBar>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>문자 발송</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일정, 회원관리 등을 공지하고 확인할 수 있어요</Text>
        </TextWrapper>
        <SendButton onClick={onClickSendMessage}>문자 전송</SendButton>
      </TitleBar>
      <MessageBoxWrapper>
        {
          isSending &&
          <LoadingView>
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
          </LoadingView>
        }
        <MessageBox>
          <WriteCommponentWrapper style={{ marginTop: '28px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>제목</Text>
              <TitleInputView>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#D9D9D9' hover='default'>{storeName}</Text>
              </TitleInputView>
          </WriteCommponentWrapper>
          <WriteCommponentWrapper style={{ marginTop: '28px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>내용</Text>
            <ContentInput
              value={content}
              setValue={setContent}
              placeholder='내용을 입력해주세요' />
          </WriteCommponentWrapper>
          <MessageSettingWrapper>
            <SettingInnerWrapper>
              <TotalCheckBoxWrapper>
                <CheckBoxWrapper onClick={() => setIsClickedValid(!isClickedValid)}>
                  <CheckBox isClicked={isClickedValid} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>유효 회원</Text>
                </CheckBoxWrapper>
                <CheckBoxWrapper onClick={() => setIsClickedInvalid(!isClickedInvalid)}>
                  <CheckBox isClicked={isClickedInvalid} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>만료 회원</Text>
                </CheckBoxWrapper>
              </TotalCheckBoxWrapper>
              <SettingTextWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='default'>{decideMemberCount() + ' 명 회원에게 문자 메시지 발송시'}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{decideCalcPrice() + '원'}</Text>
              </SettingTextWrapper>
            </SettingInnerWrapper>
          </MessageSettingWrapper>
        </MessageBox>
        <MockUpWrapper>
          <MockUp src={MockUpImage}/>
          <MockUpComponent>
            <MockUpComponentInnerWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#A4A4A4' hover='default'>단말기 설정에 따라 다르게 보일 수 있습니다</Text>
              <MockUpTitleBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#000000' hover='default'>{storeName}</Text>
              </MockUpTitleBox>
              <MockUpContentBox>
                <PreText fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000' hover='default'>{content}</PreText>
              </MockUpContentBox>
            </MockUpComponentInnerWrapper>
          </MockUpComponent>
        </MockUpWrapper>
      </MessageBoxWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin-left: 42px;
  margin: 0px 0px 252px 42px;

  width: 100%;
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 992px;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const SendButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const MessageBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 16px;
`;


const MockUpWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 246px;
  height: 477px;
`;

const MockUp = styled.img`
  position: absolute;

  width: 246px;
  height: 477px;

  z-index: 1;

  object-fit: contains;
`;

const MockUpComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  z-index: 5;

  width: 246px;
  height: 477px;
`;

const MockUpComponentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 36px;

  width: 80%;
`;

const MockUpTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;
  margin-top: 16px;

  width: calc(100% - 16px);
  height: 30px;

  border-radius: 5px;
  background-color: rgba(217, 217, 217, 0.30);
`;

const MockUpContentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 8px 8px 8px;
  margin-top: 16px;

  width: calc(100% - 16px);
  height: 303px;

  border-radius: 5px;
  background-color: rgba(217, 217, 217, 0.30);

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}

  transition: all 0.3s ease-in-out;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 28px;

  width: 627px;
  // height: 511px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const LoadingView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  margin-top: 28px;

  justify-content: center;
  align-items: center;

  width: 627px;
  height: 526px;
  
  border-radius: 30px;
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
`;

const WriteCommponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;

  gap: 8px;
`;

const TitleInputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 18px);
  height: 30px;

  overflow: hidden;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const MessageSettingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 100%;
  height: 41px;

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: rgba(255, 139, 72, 0.30);
`;

const SettingInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TotalCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const CheckBox = styled.div`
  width: 14px;
  height: 14px;

  border: ${props => props.isClicked ? '1px solid #FFFFFF' : '1px solid #DFDFDF'};
  border-radius: 16px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SettingTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  gap: 4px;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  white-space: pre-wrap;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;